define("gm-ui/controllers/index", ["exports", "@ember/controller", "@ember/service"], function (_exports, _controller, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let IndexController = (_class = class IndexController extends _controller.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "notifications", _descriptor, this);

      _defineProperty(this, "queryParams", ['token']);

      _defineProperty(this, "queryParams", ['tokenObj']);

      _defineProperty(this, "token", null);

      _defineProperty(this, "tokenObj", null);

      _defineProperty(this, "sending", false);

      _defineProperty(this, "sent", false);

      _defineProperty(this, "timeZones", {
        '-0500': 'EST',
        '-0600': ''
      });

      _defineProperty(this, "username", '');

      _defineProperty(this, "email", '');

      _defineProperty(this, "gdate", null);
    }

    async send() {
      console.info('FIELD:', this.get('email'));
      console.info('FIELD:', this.get('username'));
      console.info('FIELD-date1:', this.get('gdate'));
      console.info('MODEL:', this.get('model'));
      let responseModel = this.get('model');

      if (this.gdate == null) {
        console.info('GDATE = NULL:');
        responseModel.timeSlots.forEach(block => {
          block.slots.forEach(slot => {
            if (responseModel.selectedId == slot.id) {
              this.set('gdate', slot.id);
            }
          });
        });
        console.info('FIELD-date1:', this.get('gdate'));
      } // validate


      if (!this.username || this.gdate == null || !this.email) {
        // Show error
        this.notifications.error('Missing Required fields', {
          htmlContent: false,
          autoClear: true,
          clearDuration: 2000
        });
      } else if (!this.isValidEmail(this.email)) {
        this.notifications.error('Invalid Email', {
          htmlContent: false,
          autoClear: true,
          clearDuration: 2000
        });
      } else {
        // Send email back to the user
        this.set('sending', true);
        let response = await fetch('/api/send_mail2.php?token=' + responseModel.token + '&selectedDate=' + this.gdate + '&confirmingUserName=' + this.username + '&confirmingUserEmail=' + this.email, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        }); // let response = await fetch('/send_mail.php', {
        //   method: 'POST',
        //   headers: {
        //     'Content-Type': 'application/json'
        //   },
        //   body: JSON.stringify(data)
        //   // body: JSON.stringify({token})
        //   // body: JSON.stringify('token='+this.tokenObj)
        //   // body: JSON.stringify('test')
        // });
        // Show success conformation
        // this.notifications.success('Meeting conformation was sent', {
        //   htmlContent: false,
        //   autoClear: true,
        //   clearDuration: 2000
        // });
        // Finish

        this.set('sending', false);
        this.set('sent', true);
      }
    }

    setDate(id) {
      console.info('ID:', id);
      this.set('gdate', id);
    }

    isValidEmail(email) {
      return String(email).toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "notifications", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
  _exports.default = IndexController;
});