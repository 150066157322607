define("gm-ui/components/event-time", ["exports", "@ember/component", "@ember/template-factory"], function (_exports, _component, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{yield}}
  
  <!--<h3>MAXIM TESTING</h3>-->
  
  {{"showTime"}}
  {{eventtime "showTime"}}
  */
  {
    "id": "jHW8fxAC",
    "block": "[[[18,1,null],[1,\"\\n\\n\"],[3,\"<h3>MAXIM TESTING</h3>\"],[1,\"\\n\\n\"],[1,\"showTime\"],[1,\"\\n\"],[1,[28,[35,1],[\"showTime\"],null]]],[\"&default\"],false,[\"yield\",\"eventtime\"]]",
    "moduleName": "gm-ui/components/event-time.hbs",
    "isStrictMode": false
  });

  var _default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _component.default.extend({
    actions: {
      showTime() {
        // this.set('confirmShown', true);
        console.info('HAHA---------------------');
        return "12:12";
      } // submitConfirm() {
      //   // trigger action on parent component
      //   this.set('confirmShown', false);
      // },
      //
      // cancelConfirm() {
      //   this.set('confirmShown', false);
      // }


    }
  }));

  _exports.default = _default;
});