define("gm-ui/helpers/eventtime", ["exports", "@ember/component/helper", "moment"], function (_exports, _helper, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function eventtime(args) {
    let [eventDate, time, showTz] = args;
    const arr1 = time.split(" ");
    const arr2 = arr1[0].split(":"); // 2022-02-28T16:00:00.339Z

    const timeArr1 = eventDate.split("T");
    const timeArr2 = timeArr1[1].split(":"); // let newDateStr = timeArr1[0] + 'T' + arr2[0] + ':' + arr2[1] + ':' + timeArr2[2];

    let hour = arr1[1] === 'AM' ? arr2[0] : (Number(arr2[0]) + 12).toString();
    hour = hour == '24' ? '00' : hour;
    let newDateStr = timeArr1[0] + 'T' + hour.toString() + ':' + arr2[1] + ':' + timeArr2[2]; // console.info('======= PART =========:', arr2[0])

    console.info('======= TIME =========:', time);
    console.info('======= ORIGINAL =========:', eventDate);
    console.info('+++++++ NEW ORIGINAL +++++++:', newDateStr);
    console.info('+++++++ NEW ORIGINAL FORMAT +++++++:', (0, _moment.default)(newDateStr).format('YYYY-MM-DDTHH:mm ZZ'));
    console.info('+++++++ NEW DATE FORMAT +++++++:', new Date(newDateStr).toString()); // let momentObj = moment(eventDate)

    let momentObj = (0, _moment.default)(newDateStr); // let momentObj = moment.tz(eventDate)
    // let momentObj = moment.tz(eventDate, '')
    // let utcObj = moment().utc(eventDate)
    // let dateObj = Date.parse(eventDate)
    // let dateObj = new Date(eventDate)
    // console.info('======= ORIGINAL DATE =========:', dateObj)
    // console.log('========getTimezoneOffset:' + dateObj.getTimezoneOffset());
    // console.info('======= ORIGINAL FORMAT =========:', moment(eventDate).utc().format('YYYY-MM-DDTHH:mm:ssZZ'));
    // console.info('======= ORIGINAL FORMAT 2 =========:', moment().utc(eventDate).format('YYYY-MM-DDTHH:mm:ssZZ'));
    // console.info('======= ORIGINAL FORMAT 2 =========:', moment().utc(eventDate).format(''));
    // SET to proper TIME
    // momentObj.set('hour', arr1[1] === 'AM' ?  Number(arr2[0]) : (Number(arr2[0]) + 12));
    // momentObj.set('minute', Number(arr2[1]));
    // console.info('======= FORMAT BEFORE =========:', momentObj.format('hh:mm A (z)'))
    // console.info('momentObj:', momentObj.format())
    // console.log(Intl.DateTimeFormat().resolvedOptions().timeZone)
    // Get user locale

    var tz = _moment.default.tz.guess(); // momentObj.tz('Asia/Calcutta');
    // Change timezone to local user locale
    // momentObj.tz(tz);
    // console.info('======= FORMAT AFTER =========:', momentObj.format('hh:mm A (z)'))


    let str = '';

    if (showTz) {
      str = momentObj.format('hh:mm A (z)');
    } else {
      str = momentObj.format('hh:mm A');
    }

    return str;
  }

  var _default = (0, _helper.helper)(eventtime);

  _exports.default = _default;
});