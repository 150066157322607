define("gm-ui/routes/index", ["exports", "@ember/routing/route"], function (_exports, _route) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class IndexRoute extends _route.default {
    constructor() {
      super(...arguments);

      _defineProperty(this, "queryParams", {
        token: {
          replace: null
        },
        selectedid: {
          replace: null
        }
      });
    }

    // console.log('asdas');
    async model(params) {
      return fetch('/api/get_schedule.php?token=' + params.token).then(async function (response) {
        // return response.json();
        // console.info('RESPONCE:', response.json());
        let responseData = await response.json();
        console.info('RESPONCE:', responseData);
        console.info('selecteddate:', params.selectedid);
        let schedule = JSON.parse(responseData.data);
        schedule.selectedId = parseInt(params.selectedid);
        schedule.token = params.token;
        console.info('schedule:', schedule);
        schedule.timeSlots.forEach(e => {// decodedData.timeSlots.forEach(e => {
          // e.date = Date.parse(e.date); //TODO ??????
          // e.slots.forEach(s => {
          //   e.endtTime = Date.parse(s.endtTime);
          //   e.startTime = Date.parse(s.startTime);
          // });
        });
        return schedule;
      });
      /*
      try {
        // var decodedString = atob(params.token);
        // console.info('STRING:', decodedString);
        this.set('tokenObj', {});
        console.info('STRING:', params.token);
        // var decodedData = JSON.parse(decodedString);
         // let response = await fetch('/api/get_schedule.php?token=' + decodedData.token, {
        // let response = await fetch('/api/get_schedule.php?token=' + params.token, {
        //   method: 'GET',
        //   headers: {
        //     'Content-Type': 'application/json'
        //   }
        // });
        // console.info('RESPONCE:', response.json());
          // let response = await fetch('/api/get_schedule.php?token=' + params.token);
        // // let { data } = await response.json();
        // let responceData = await response.json();
        // console.info('RESPONCE:', responceData);
        //
        // let schedule = JSON.parse(responceData.data);
        // console.info('schedule:', schedule);
          fetch('/api/get_schedule.php?token=' + params.token).then(async function (response) {
          // return response.json();
          // console.info('RESPONCE:', response.json());
          let responseData = await response.json();
          console.info('RESPONCE:', responseData);
          let schedule = JSON.parse(responseData.data);
          console.info('schedule:', schedule);
          schedule.timeSlots.forEach(e => {
            // decodedData.timeSlots.forEach(e => {
            // e.date = Date.parse(e.date); //TODO ??????
            // e.slots.forEach(s => {
            //   e.endtTime = Date.parse(s.endtTime);
            //   e.startTime = Date.parse(s.startTime);
            // });
          });
          return schedule;
        });
        // }).then(results => {
        //   // this.set('flightResults', flightResults);
        //   console.info('results:', results);
        //   this.set('tokenObj', results);
        //   return results;
        // });
        } catch (e) {
        //alert(e); // error in the above string (in this case, yes)!
        // decodedData = null;
        console.error('Could not parse JSON')
      }
       // console.info('TESTING:', decodedData);
      // this.set('tokenObj', schedule);
      */
    }

    setupController(controller, model) {
      super.setupController(...arguments);
      controller.set('tokenObj', this.tokenObj);
    }

  }

  _exports.default = IndexRoute;
});